@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		/* --font-sans: here if you have one */
		/* --font-mono: here if you got it... */

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345, 82.7%, 40.8%;

		--background: 220, 14%, 96%;
		--foreground: 208, 13%, 45%;

		--muted: 210, 40%, 93%;
		--muted-foreground: 176, 29%, 66%;

		--popover: 168, 33%, 97%;
		--popover-foreground: 178, 29%, 49%;

		--card: 0, 0%, 100%;
		--card-foreground: 222.2, 84%, 4.9%;

		--border: 227, 74%, 86%;
		--input: 219, 57%, 38%;
		--input-invalid: 9, 68%, 54%;

		--primary: 176, 29%, 66%;
		--primary-30: 168, 33%, 97%;
		--primary-40: 175, 30%, 93%;
		--primary-50: 177, 30%, 88%;
		--primary-60: 175, 29%, 84%;
		--primary-70: 176, 30%, 79%;
		--primary-80: 177, 30%, 75%;
		--primary-90: 177, 28%, 70%;
		--primary-120: 177, 29%, 57%;
		--primary-130: 178, 29%, 49%;
		--primary-140: 177, 29%, 40%;
		--primary-150: 176, 29%, 32%;
		--primary-160: 176, 29%, 23%;
		--primary-170: 177, 29%, 14%;
		--primary-foreground: 219, 57%, 15%;

		--secondary: 219, 57%, 15%;
		--secondary-30: 220, 57%, 93%;
		--secondary-40: 220, 57%, 82%;
		--secondary-50: 219, 57%, 71%;
		--secondary-60: 219, 57%, 60%;
		--secondary-70: 219, 57%, 49%;
		--secondary-80: 219, 57%, 38%;
		--secondary-90: 219, 57%, 27%;
		--secondary-120: 219, 56%, 23%;
		--secondary-140: 219, 57%, 15%;
		--secondary-foreground: 0, 0%, 100%;

		--accent: 9, 68%, 62%;
		--accent-30: 11, 65%, 97%;
		--accent-40: 9, 67%, 92%;
		--accent-50: 9, 68%, 87%;
		--accent-60: 10, 68%, 82%;
		--accent-70: 9, 68%, 77%;
		--accent-80: 9, 68%, 72%;
		--accent-90: 9, 68%, 67%;
		--accent-120: 9, 68%, 54%;
		--accent-130: 9, 68%, 46%;
		--accent-140: 9, 68%, 38%;
		--accent-150: 9, 68%, 30%;
		--accent-160: 9, 67%, 22%;
		--accent-170: 9, 68%, 14%;
		--accent-foreground: 31, 64%, 83%;

		--destructive: 0, 88%, 47%;
		--destructive-foreground: 0, 0%, 100%;

		--ring: 219, 57%, 38%;

		--radius: 0.5rem;

		--navbar: 219, 57%, 38%;
		--navbar-foreground: 0, 0%, 100%;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 178, 29%, 49%;
		--popover-foreground: 168, 33%, 97%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 40% 98%;

		--accent: 217.2 32.6% 10%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 0 85.7% 97.3%;

		--ring: 217.2 32.6% 60%;

		--navbar: 223, 94%, 57%;
		--navbar-foreground: 227, 100%, 94%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
